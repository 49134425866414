//React
import React from "react"
import { graphql } from "gatsby"

//Components
import Layout from "../layout"
import Seo from "../components/seo"
import Svg from '../images/statys-background-animation-large.svg'

//Chakra UI
import {
  Box,
  Flex,
  Image,
  Text, 
  useBreakpointValue,
} from '@chakra-ui/react'

const NotFoundPage = ({ data, location }) => {

  const isMobile = useBreakpointValue({ base: true, xl: false })
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />

      <Box height="100vh">
        {!isMobile && (<Image src={Svg} style={{ position: `absolute`, top: `-72px`, left: `0`, height: `-webkit-fill-available` }}></Image>)}
        
        <Flex flexDirection="column" alignItems="center" justifyContent="center" height="inherit">
          <Text as="h1" color="blue.500"  pb="8px" fontSize="48px !important" fontFamily="Gotham Medium" fontWeight="300"> 404 Error </Text>
          <Text>This page doesn't exist. <br></br> Please go back to <a href="/">Home page.</a> </Text>
        </Flex>
      </Box>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
